import { configureStore } from '@reduxjs/toolkit';
import { interceptor } from '../services/Api/Api';
import authReducer from '../domains/login/feature/authSlice';
import dashboardReducer from '../domains/dashboard/PageDashboard/feature/dashboardSlice';
import notificationsReducer from './components/Layout/feature/notificationsSlice';
import administrationReducer from '../domains/administration/PageAdministration/feature/administrationSlice';
import userDetailsReducer from '../domains/administration/PageUserDetails/feature/userDetailsSlice';
import questionsReducer from '../domains/questions/PageQuestions/feature/questionsSlice';
import questionDetailsReducer from '../domains/questions/PageCreateEditQuestion/feature/questionDetailsSlice';
import testsReducer from '../domains/logical-test/feature/logicalTestSlice';



const reducer = {
  auth: authReducer,
  dashboard: dashboardReducer,
  notifications: notificationsReducer,
  administration: administrationReducer,
  userDetails: userDetailsReducer,
  questions: questionsReducer,
  questionDetails: questionDetailsReducer,
  tests: testsReducer,
};


const store = configureStore({
  reducer,
  devTools: true,
});

interceptor(store);

export default store;
