import React from 'react';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Select } from 'antd';
import Yup from '../../../../vendor/yup';
import { ROLES } from '../../../../const/system';



const { Option } = Select;

const FormCreateUserButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateUserButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;


const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Минимум 2 символа', { limit: 2 })
    .max(20, 'Максимум 20 символов', { limit: 20 })
    .required('Обязательное поле'),
  email: Yup.string().email('Неправильный формат почты').required('Обязательное поле'),
});


const FormCreateUser = ({
  onSubmit,
  initialValues,
  isSubmitting,
  formErrors,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, setFieldValue, dirty, values } = props;

        return (
          <Form layout="vertical" className='kt-w-100'>
            <FormItem
              className='ant-form-item-col'
              name="role"
              label="Роль:"
              required
            >
              <Select
                name="role"
                placeholder="Выберите роль"
                value={values.role}
                optionFilterProp="children"
                onChange={(value) => {
                  setFieldValue('role', value);
                }}
              >
                {ROLES.map((item) => {
                  return (
                    <Option key={item.value} value={item.value}>{item.label}</Option>
                  );
                })}
              </Select>
            </FormItem>
            <FormItem
              className='ant-form-item-col'
              name="name"
              label="Имя:"
              required
            >
              <Input
                name='name'
                placeholder='Укажите имя пользователя'
                onChange={(event) => {
                  setFieldValue('name', event.target.value.replace(/\s/g, ''));
                }}
              />
            </FormItem>

            <FormItem
              className='ant-form-item-col'
              name="email"
              label="Почта:"
              required
            >
              <Input
                name='email'
                placeholder='Укажите почту'
              />
            </FormItem>


            <FormCreateUserButtonsRow>
              <FormCreateUserButtonWr>
                <SubmitButton
                  className='kt-w-100'
                  loading={isSubmitting}
                  disabled={!isValid || !dirty}
                >
                  Добавить
                </SubmitButton>
              </FormCreateUserButtonWr>
            </FormCreateUserButtonsRow>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateUser.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    role: PropTypes.string,
  }).isRequired,
  values: PropTypes.shape({
    role: PropTypes.string,
  }),
  formErrors: PropTypes.array,
  isSubmitting: PropTypes.bool.isRequired,
};

FormCreateUser.defaultProps = {
  formErrors: [],
};

export default FormCreateUser;
