import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '../services/authService';
import Api from '../../../services/Api/Api';
import { currentRole, USER_ROLES } from '../../../const/system';
import urlPageLogicalTest from '../../../urls/urlPageLogicalTest';
import urlPageDashboard from '../../../urls/urlPageDashboard';



const initialState = {
  data: {
    isAuthenticated: !!localStorage.getItem('token'),
    user: {},
  },
  loading: false,
  errors: null,
};

const authService = new AuthService(Api);

export const getAuthUser = createAsyncThunk(
  'setAuth/getAuth',
  async (token) => {
    if (token.hasOwnProperty('expired_in')) {
      localStorage.setItem('token', JSON.stringify(token));
    }
    // eslint-disable-next-line no-return-await
    return await authService.getAuth();
  },
);

export const authSlice = createSlice({
  name: 'setAuth',
  initialState,
  reducers: {
    logoutUser: (state) => {
      state.data.isAuthenticated = false;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAuthUser.pending, (state) => {
        state.loading = true;
        state.errors = null;
      })
      .addCase(getAuthUser.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        localStorage.setItem('user', JSON.stringify(action.payload));
        window.location.replace(currentRole === USER_ROLES.USER ? urlPageLogicalTest() : urlPageDashboard());
      })
      .addCase(getAuthUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = action.error.message;

        state.data.isAuthenticated = false;
        localStorage.removeItem('token');
      });
  },
});

export const { logoutUser } = authSlice.actions;

// selectors
export const authLoading = (state) => state.auth.loading;
export const authErrors = (state) => state.auth;
export const authData = (state) => state.auth.data;

// reducer
export default authSlice.reducer;
