import { Field, Form, FormItem, SubmitButton } from 'formik-antd';
import { FieldArray, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { Radio } from 'antd';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { checkDeviceScreen } from '../../../hooks/checkDeviceScreen';



const FormCreateUserButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateUserButtonWr = styled.div`
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;

const RadioWrapper = styled.div`
  &:hover {
    opacity: 0.5;
  }
`;


const FormQuestions = ({
  initialValues,
  isSubmitting,
  onSubmit,
  isLastQuestion,
}) => {
  return (
    <Formik
      enableReinitialize
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={[]}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { dirty, values } = props;

        return (
          <Form layout="vertical" className='test-form-layout'>
            <FormItem
              className='ant-form-item-col'
              name="question"
            >
              <div className='test-question-title-font'>
                {`${values.id}. ${values.question}`}
              </div>
            </FormItem>
            <FormItem
              className='ant-form-item-col'
              name="answers"
            >
              <FieldArray
                name="answers"
                value={values.answers}
              >
                {() => {
                  return (
                    <div>
                      {!isEmpty(values?.answers) && values.answers.map((group, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                        <Field name={`answers.${index}`} key={index}>
                          {({ field: { name }, form: { setFieldValue } }) => {
                            return (
                              <FormItem
                                className='ant-form-item-col'
                                name={`${name}.text`}
                              >
                                <RadioWrapper>
                                  <Radio.Group
                                    value={values.user_answer_id}
                                    onChange={(event) => {
                                      setFieldValue('user_answer_id', event.target.value);
                                    }}
                                  >
                                    <Radio
                                      className='radio-large'
                                      disabled={isSubmitting}
                                      value={values.answers[index].answer_id}
                                    >
                                      <div className='test-question-text-font'>
                                        {values.answers[index].text}
                                      </div>
                                    </Radio>
                                  </Radio.Group>
                                </RadioWrapper>
                              </FormItem>
                            );
                          }}
                        </Field>
                      )) }
                    </div>
                  );
                }}
              </FieldArray>
            </FormItem>

            <FormCreateUserButtonsRow>
              <FormCreateUserButtonWr>
                <SubmitButton
                  size={checkDeviceScreen() ? 'medium' : 'large'}
                  loading={isSubmitting}
                  disabled={!dirty}
                >
                  {isLastQuestion ? 'Завершить тест' : 'Следующий вопрос'}
                </SubmitButton>
              </FormCreateUserButtonWr>
            </FormCreateUserButtonsRow>
          </Form>
        );
      }}
    </Formik>
  );
};


FormQuestions.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    id: PropTypes.string,
    question: PropTypes.string,
    answers: PropTypes.array,
    user_answer_id: PropTypes.string,
  }),
  formErrors: PropTypes.array,
  isSubmitting: PropTypes.bool.isRequired,
  isLastQuestion: PropTypes.bool.isRequired,
};

FormQuestions.defaultProps = {
  formErrors: [],
  values: {},
};

export default FormQuestions;
