import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import { mkNotification } from '../../../../lib/mkNotification';
import UserDetailsService from '../services/userDetailsService';



const initialState = {
  userDetailsData: {},
  loadingUserDetails: false,
};

const userDetailsService = new UserDetailsService(Api);

export const getUserDetailsData = createAsyncThunk(
  'userDetails/getUsersListData',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await userDetailsService.getUserDetails(userId);

      if (isEmpty(response)) {
        mkNotification('info', 'Нет данных для отображения пользователя.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const userDetailsSlice = createSlice({
  name: 'userDetails',
  initialState,
  reducers: {
    resetUserDetailsData: (state) => {
      state.usersListData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserDetailsData.pending, (state) => {
        state.loadingUserDetails = true;
      })
      .addCase(getUserDetailsData.fulfilled, (state, action) => {
        state.loadingUserDetails = false;
        state.userDetailsData = action.payload;
      })
      .addCase(getUserDetailsData.rejected, (state) => {
        state.loadingUserDetails = false;
      });

  },
});

// actions
export const { resetUserDetailsData } = userDetailsSlice.actions;

// selectors
export const selectUserDetailsData = (state) => state.userDetails.userDetailsData;
export const selectUserDetailsLoading = (state) => state.userDetails.loadingUserDetails;

// reducer
export default userDetailsSlice.reducer;
