import React from 'react';
import { format } from 'date-fns';
import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import urlPageEditQuestion from '../../../urls/urlPageEditQuestion';



export const getQuestionsListTableColumns = (handleDeleteQuestion) => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    width: '7%',
    sorter: (_a, _b) => _a.question_id - _b.question_id,
    render: (value) => <div>{value}</div>,
  },
  {
    title: 'Вопрос',
    dataIndex: 'question',
    width: '30%',
    render: (item) => <div>{item || '---'}</div>,
  },
  {
    title: 'Кол-во ответов',
    align: 'center',
    width: '7%',
    render: (value) => <div>{value.answers.length}</div>,
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    align: 'center',
    width: '15%',
    render: (value) => <>{format(new Date(value), 'dd.MM.yyyy HH:mm')}</>,
    sorter: (_a, _b) => _a.created_at - _b.created_at,
  },
  {
    title: 'Дата редактирования',
    dataIndex: 'updated_at',
    align: 'center',
    width: '15%',
    render: (value) => <div>{value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : '---'}</div>,
    sorter: (_a, _b) => _a.updated_at - _b.updated_at,
  },
  {
    title: 'Действие',
    key: 'action',
    align: 'center',
    width: '10%',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          <Popconfirm
            title="Вы уверены что хотите удалить этот вопрос?"
            placement="topRight"
            okText="Да"
            cancelText="Отмена"
            onConfirm={() => handleDeleteQuestion(item.id)}
          >
            <Tooltip placement="top" title='Удалить вопрос'>
              <Button type='primary' danger size='small' className='kt-mr-5'>
                <DeleteOutlined size={18} />
              </Button>
            </Tooltip>
          </Popconfirm>
          <Tooltip placement="top" title='Детали'>
            <Button type='primary' size='small'>
              <Link to={urlPageEditQuestion({ questionId: item.id })}>
                <EyeOutlined />
              </Link>
            </Button>
          </Tooltip>
        </Row>
      );
    },
  },
];
