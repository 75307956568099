export default class QuestionDetailsService {
  constructor (Api) {
    this.api = Api;
  }

  createQuestion (values) {
    return this.api.post('questions', values);
  }

  getQuestionDetails (questionId) {
    return this.api.get(`questions/${questionId}`);
  }

  updateQuestion ({ questionId, data }) {
    return this.api.put(`questions/${questionId}`, data);
  }
}
