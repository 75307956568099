import { Button, Divider, Menu } from 'antd';
import React from 'react';
import { RiSettings5Line, RiQuestionLine } from 'react-icons/ri';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutButton from '../../LogoutButton';
import urlPageAdministration from '../../../../urls/urlPageAdministration';
import urlPageQuestions from '../../../../urls/urlPageQuestions';
import { hasAdminRights } from '../../../../const/system';



const UserDropdown = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <Menu selectedKeys={[ pathname.substring(1) ]}>
      {hasAdminRights && (
        <>
          <Menu.Item key='administration'>
            <Button
              onClick={() => {
                navigate(urlPageAdministration());
              }}
              type='link'
              icon={<RiSettings5Line fill='#56A9A2' className='kt-mr-5' size={18} />}
            >
              Управление
            </Button>
          </Menu.Item>
          <Menu.Item key='questions'>
            <Button
              onClick={() => {
                navigate(urlPageQuestions());
              }}
              type='link'
              icon={<RiQuestionLine fill='#56A9A2' className='kt-mr-5' size={18} />}
            >
              Вопросы
            </Button>
          </Menu.Item>
          <Divider style={{ margin: 'unset' }} />
        </>
      )}
      <Menu.Item key='logout'>
        <LogoutButton />
      </Menu.Item>
    </Menu>
  );
};

export default UserDropdown;
