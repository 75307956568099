import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Table, Input } from 'antd';
import { RiCloseFill } from 'react-icons/ri';
import isEmpty from 'lodash/isEmpty';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersListTableColumns } from './PageAdministration.const';
import FormCreateUser from './FormCreateUser';
import {
  createUser,
  deleteUser,
  getUsersListData,
  resetUsersListData,
  selectAddUserErrors,
  selectAddUserLoading,
  selectUsersListData,
  selectUsersListLoading,
} from './feature/administrationSlice';



const PageAdministration = () => {
  const dispatch = useDispatch();
  const [ isActiveUserModal, setToggleCreateUserModal ] = useState(false);
  const [ suggestionsData, setSuggestionsData ] = useState(null);
  const [ searchValue, setSearchValue ] = useState('');

  const usersList = useSelector(selectUsersListData);
  const loadingUsersList = useSelector(selectUsersListLoading);
  const loadingAddUserForm = useSelector(selectAddUserLoading);
  const addUserFormErrors = useSelector(selectAddUserErrors);

  useEffect(() => {
    dispatch(getUsersListData());

    return () => {
      dispatch(resetUsersListData());
    };
  }, []);

  const onSearchChange = (value) => {
    const filterTable = !isEmpty(usersList) && usersList.filter((item) => Object.keys(item).some((key) => String(item[key])
      .toLowerCase()
      .includes(value.toLowerCase())));

    setSearchValue(value);
    setSuggestionsData(filterTable);
  };

  const handleDeleteUser = (userId) => {
    dispatch(deleteUser(userId)).then(() => {
      dispatch(getUsersListData());
    });
  };

  const showCreateUserModal = () => {
    setToggleCreateUserModal(true);
  };

  const hideCreateUserModal = () => {
    setToggleCreateUserModal(false);
  };

  const handleSubmitCreateUserForm = async (values) => {
    await dispatch(createUser(values))
      .then((response) => {
        if (isEmpty(response.payload?.errors)) {
          hideCreateUserModal();
          dispatch(getUsersListData());
        }
      });
  };

  return (
    <div>
      <Modal
        title="Добавление пользователя"
        width={416}
        centered
        destroyOnClose
        visible={isActiveUserModal}
        onCancel={hideCreateUserModal}
        footer={null}
        closeIcon={
          <RiCloseFill size={24} />
        }
      >
        <FormCreateUser
          initialValues={{ role: 'user' }}
          formErrors={addUserFormErrors}
          onSubmit={handleSubmitCreateUserForm}
          isSubmitting={loadingAddUserForm}
        />
      </Modal>

      <Col span={24}>
        <Row
          justify="space-between"
          style={{ marginBottom: '15px', alignItems: 'center' }}
        >
          <div style={{ fontSize: '1.17em' }}>
            {`Всего пользователей: ${usersList.length}`}
          </div>

          <div>
            <Input
              allowClear
              placeholder="Поиск по имени..."
              enterButton
              style={{ width: 200, marginRight: 15 }}
              onChange={(event) => {
                onSearchChange(event.target.value);
              }}
              prefix={<SearchOutlined fill='#9393ab' />}
            />
            <Button
              size='middle'
              type="primary"
              onClick={showCreateUserModal}
            >
              Добавить пользователя
            </Button>
          </div>
        </Row>
      </Col>

      <Card>
        <Table
          sticky
          rowKey="id"
          loading={loadingUsersList}
          dataSource={!isEmpty(searchValue) ? suggestionsData : usersList}
          columns={getUsersListTableColumns(handleDeleteUser)}
        />
      </Card>
    </div>
  );
};


export default PageAdministration;
