export default class LogicalTestService {
  constructor (Api) {
    this.api = Api;
  }

  getTestQuestionsList () {
    return this.api.get('test-questions');
  }

  getUserTestStatus (userId) {
    return this.api.get(`test-status/${userId}`);
  }

  updateTestStatus ({ userId, values }) {
    return this.api.post(`test-status/${userId}`, values);
  }

  publishUserAnswers ({ userId, values }) {
    return this.api.post(`test-answers/${userId}`, values);
  }
}
