import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import { mkNotification } from '../../../../lib/mkNotification';
import AdministrationService from '../services/administrationService';



const initialState = {
  usersListData: [],
  loadingUsersList: false,
  formCreateUserLoading: false,
  formCreateUserErrors: {},
};

const administrationService = new AdministrationService(Api);

export const getUsersListData = createAsyncThunk(
  'administration/getUsersListData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await administrationService.getUsersListData();

      if (isEmpty(response)) {
        mkNotification('info', 'Нет данных для отображения списка пользователей.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createUser = createAsyncThunk(
  'administration/createUser',
  async (values, { rejectWithValue }) => {
    try {
      const response = await administrationService.createUser(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteUser = createAsyncThunk(
  'administration/deleteUser',
  async (userId) => {
    const response = await administrationService.deleteUser(userId);

    return response.data;
  },
);


export const administrationSlice = createSlice({
  name: 'administration',
  initialState,
  reducers: {
    resetUsersListData: (state) => {
      state.usersListData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersListData.pending, (state) => {
        state.loadingUsersList = true;
      })
      .addCase(getUsersListData.fulfilled, (state, action) => {
        state.loadingUsersList = false;
        state.usersListData = action.payload;
      })
      .addCase(getUsersListData.rejected, (state) => {
        state.loadingUsersList = false;
      });

    builder
      .addCase(createUser.pending, (state) => {
        state.formCreateUserLoading = true;
        state.formCreateUserErrors = {};
      })
      .addCase(createUser.fulfilled, (state) => {
        state.formCreateUserLoading = false;
        mkNotification('success', 'Пользователь успешно добавлен в систему.');
      })
      .addCase(createUser.rejected, (state, action) => {
        state.formCreateUserLoading = false;
        state.formCreateUserErrors = action.payload.errors;
      });

    builder
      .addCase(deleteUser.pending, (state) => {
        state.loadingUsersList = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.loadingUsersList = false;
        mkNotification('success', 'Пользователь успешно удален.');
      })
      .addCase(deleteUser.rejected, (state) => {
        state.loadingUsersList = false;
      });
  },
});

// actions
export const { resetUsersListData } = administrationSlice.actions;

// selectors
export const selectUsersListData = (state) => state.administration.usersListData;
export const selectUsersListLoading = (state) => state.administration.loadingUsersList;
export const selectAddUserLoading = (state) => state.administration.formCreateUserLoading;
export const selectAddUserErrors = (state) => state.administration.formCreateUserErrors;

// reducer
export default administrationSlice.reducer;
