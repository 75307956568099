import React from 'react';
import { Button, Card, Col, Image, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import urlPageAdministration from '../../urls/urlPageAdministration';
import illustration from '../../assets/images/users-image.svg';



const WidgetNewUsers = ({ title, data, isLoading }) => {
  return (
    <Card>
      <Row justify="start">
        <h1>{title}</h1>
      </Row>

      <Row justify='space-between'>
        <Col>
          <Spin spinning={isLoading} tip="Загрузка...">
            <Row justify="space-between">
              <Col span={13}>
                <h1 className='kt-mb-15 text-secondary'>
                  {data.total_users ?? '---'}
                </h1>
                <Row>
                  <Button
                    type='link'
                    href={urlPageAdministration()}
                  >
                    Смотреть всех
                  </Button>
                </Row>
              </Col>
              <Col span={11}>
                <Image
                  className='widget-users-img'
                  referrerPolicy="no-referrer"
                  src={illustration}
                  preview={false}
                />
              </Col>
            </Row>
          </Spin>
        </Col>
      </Row>
    </Card>
  );
};


WidgetNewUsers.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
};

WidgetNewUsers.defaultProps = {
  isLoading: false,
  data: {},
};

export default WidgetNewUsers;
