import React from 'react';
import { Card, Col, Image, Row, Spin } from 'antd';
import PropTypes from 'prop-types';
import timeImg from '../../assets/images/time-image.svg';



const WidgetAvgTime = ({ title, data, isLoading }) => {
  return (
    <Card>
      <Row justify="space-between">
        <h1>{title}</h1>
      </Row>

      <Spin spinning={isLoading} tip="Загрузка...">
        <Row justify="space-between">
          <Col>
            <h1 className='text-secondary'>
              {`${data?.avg_time?.minutes ?? '---'}мин`}
              &nbsp;
              {`${data?.avg_time?.seconds ?? '---'}сек`}
            </h1>
          </Col>
          <Col className="flex-row-center" span={11}>
            <Image
              className='widget-time-img'
              referrerPolicy="no-referrer"
              src={timeImg}
              preview={false}
            />
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};


WidgetAvgTime.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
};

WidgetAvgTime.defaultProps = {
  isLoading: false,
  data: {},
};

export default WidgetAvgTime;
