import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import LogicalTestService from '../services/logicalTestService';
import { mkNotification } from '../../../lib/mkNotification';
import Api from '../../../services/Api/Api';



const initialState = {
  questionsList: [],
  loadingQuestionsList: false,
  loadingTestStatus: false,
  userTestStatus: null,
  loadingUserAnswers: false,
};

const logicalTestService = new LogicalTestService(Api);

export const getTestQuestionsList = createAsyncThunk(
  'tests/getTestQuestionsList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await logicalTestService.getTestQuestionsList();

      if (isEmpty(response)) {
        mkNotification('info', 'Нет данных для отображения вопросов.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const getUserTestStatus = createAsyncThunk(
  'tests/getUserTestStatus',
  async (userId, { rejectWithValue }) => {
    try {
      const response = await logicalTestService.getUserTestStatus(userId);

      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateTestStatus = createAsyncThunk(
  'tests/updateTestStatus',
  async ({ userId, values }, { rejectWithValue }) => {
    try {
      const response = await logicalTestService.updateTestStatus({ userId, values });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const publishUserAnswers = createAsyncThunk(
  'tests/publishUserAnswers',
  async ({ userId, values }, { rejectWithValue }) => {
    try {
      const response = await logicalTestService.publishUserAnswers({ userId, values });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const logicalTestSlice = createSlice({
  name: 'tests',
  initialState,
  reducers: {
    resetTestQuestionsListData: (state) => {
      state.questionsList = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestQuestionsList.pending, (state) => {
        state.loadingQuestionsList = true;
      })
      .addCase(getTestQuestionsList.fulfilled, (state, action) => {
        state.loadingQuestionsList = false;
        state.questionsList = action.payload;
      })
      .addCase(getTestQuestionsList.rejected, (state) => {
        state.loadingQuestionsList = false;
      });

    builder
      .addCase(getUserTestStatus.pending, (state) => {
        state.loadingTestStatus = true;
      })
      .addCase(getUserTestStatus.fulfilled, (state, action) => {
        state.loadingTestStatus = false;
        state.userTestStatus = action.payload;
      })
      .addCase(getUserTestStatus.rejected, (state) => {
        state.loadingTestStatus = false;
      });

    builder
      .addCase(updateTestStatus.pending, (state) => {
        state.loadingTestStatus = true;
      })
      .addCase(updateTestStatus.fulfilled, (state) => {
        state.loadingTestStatus = false;
      })
      .addCase(updateTestStatus.rejected, (state) => {
        state.loadingTestStatus = false;
      });

    builder
      .addCase(publishUserAnswers.pending, (state) => {
        state.loadingUserAnswers = true;
      })
      .addCase(publishUserAnswers.fulfilled, (state) => {
        state.loadingUserAnswers = false;
        mkNotification('success', 'Ответы на тестовые вопросы успешно опубликованы.');
      })
      .addCase(publishUserAnswers.rejected, (state) => {
        state.loadingUserAnswers = false;
      });
  },
});

// actions
export const { resetTestQuestionsListData } = logicalTestSlice.actions;

// selectors
export const selectTestQuestionsListData = (state) => state.tests.questionsList;
export const selectTestQuestionsListLoading = (state) => state.tests.loadingQuestionsList;
export const selectTestStatusLoading = (state) => state.tests.loadingTestStatus;
export const selectUserTestStatus = (state) => state.tests.userTestStatus;
export const selectTestAnswersListLoading = (state) => state.tests.loadingUserAnswers;

// reducer
export default logicalTestSlice.reducer;
