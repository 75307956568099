import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import { mkNotification } from '../../../../lib/mkNotification';
import DashboardService from '../services/dashboardService';



const initialState = {
  widgetsData: {},
  loadingWidgets: false,
};

const dashboardService = new DashboardService(Api);

export const getWidgetsData = createAsyncThunk(
  'dashboardWidgets/getWidgetsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await dashboardService.getWidgetsData();

      if (isEmpty(response)) {
        mkNotification('info', 'Нет данных для отображения виджетов.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const dashboardSlice = createSlice({
  name: 'dashboardWidgets',
  initialState,
  reducers: {
    resetWidgetsData: (state) => {
      state.widgetsData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWidgetsData.pending, (state) => {
        state.loadingWidgets = true;
      })
      .addCase(getWidgetsData.fulfilled, (state, action) => {
        state.loadingWidgets = false;
        state.widgetsData = action.payload;
      })
      .addCase(getWidgetsData.rejected, (state) => {
        state.loadingWidgets = false;
      });
  },
});

// actions
export const { resetWidgetsData } = dashboardSlice.actions;

// selectors
export const selectWidgetsLoading = (state) => state.dashboard.loadingWidgets;
export const selectWidgetsData = (state) => state.dashboard.widgetsData;

// reducer
export default dashboardSlice.reducer;
