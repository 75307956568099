import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import './assets/less/themes/antd-customized.css';
import { USER_ROLES } from './const/system';
import AccessDenied from './app/components/AccessDenied';
import PrivateRoute from './app/components/PrivateRoute';
import PageLogin from './domains/login/PageLogin';
import PageDashboard from './domains/dashboard/PageDashboard';
import PageSandbox from './domains/users/PageSandbox';
import PageAdministration from './domains/administration/PageAdministration';
import PageQuestions from './domains/questions/PageQuestions';
import PageCreateEditQuestion from './domains/questions/PageCreateEditQuestion';
import PageLogicalTest from './domains/logical-test';
import PageUserDetails from './domains/administration/PageUserDetails';
import urlPageDashboard from './urls/urlPageDashboard';
import urlPageSandbox from './urls/urlPageSandbox';
import urlPageAdministration from './urls/urlPageAdministration';
import urlPageQuestions from './urls/urlPageQuestions';
import urlPageCreateQuestion from './urls/urlPageCreateQuestion';
import urlPageEditQuestion from './urls/urlPageEditQuestion';
import urlPageLogicalTest from './urls/urlPageLogicalTest';
import urlPageUserDetails from './urls/urlPageUserDetails';
import { FEATURE_HIDE_SANDBOX } from './lib/features';



const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<PageLogin />} />
          <Route path="*" element={<AccessDenied />} />

          <Route
            path={urlPageDashboard()}
            element={<PrivateRoute roles={[ USER_ROLES.ADMIN ]} />}
          >
            <Route path='' element={<PageDashboard />} />
          </Route>

          <Route
            path={urlPageLogicalTest()}
            element={<PrivateRoute roles={[ USER_ROLES.USER, USER_ROLES.ADMIN ]} />}
          >
            <Route path='' element={<PageLogicalTest />} />
          </Route>

          {FEATURE_HIDE_SANDBOX && (
            <Route
              path={urlPageSandbox()}
              element={<PrivateRoute roles={[ USER_ROLES.ADMIN ]} />}
            >
              <Route path='' element={<PageSandbox />} />
            </Route>
          )}

          <Route
            path={urlPageAdministration()}
            element={<PrivateRoute roles={[ USER_ROLES.ADMIN ]} />}
          >
            <Route path='' element={<PageAdministration />} />
            <Route path={urlPageUserDetails()} element={<PageUserDetails />} />
          </Route>

          <Route
            path={urlPageQuestions()}
            element={<PrivateRoute roles={[ USER_ROLES.ADMIN ]} />}
          >
            <Route path='' element={<PageQuestions />} />
            <Route path={urlPageCreateQuestion()} element={<PageCreateEditQuestion />} />
            <Route path={urlPageEditQuestion()} element={<PageCreateEditQuestion />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
