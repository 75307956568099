import React from 'react';
import urlPageSandbox from '../../../urls/urlPageSandbox';
import urlPageDashboard from '../../../urls/urlPageDashboard';
import urlPageLogicalTest from '../../../urls/urlPageLogicalTest';
import { USER_ROLES } from '../../../const/system';
import { FEATURE_HIDE_SANDBOX } from '../../../lib/features';



export const MenuItems = [
  {
    label: 'Доска',
    key: 'dashboard',
    navLink: urlPageDashboard(),
    roles: [ USER_ROLES.ADMIN ],
    icon: '',
  },
  {
    label: 'Тест',
    key: 'test',
    navLink: urlPageLogicalTest(),
    roles: [ USER_ROLES.ADMIN ],
    icon: '',
  },
  FEATURE_HIDE_SANDBOX ? { label: 'Песочница',
    key: 'sandbox',
    navLink: urlPageSandbox(),
    roles: [ USER_ROLES.ADMIN ],
    icon: '',
  } : null,
];
