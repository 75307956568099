import React, { useCallback, useEffect, useRef, useState } from 'react';



export const useCountdown = (timerValue = 1800) => {
  const initialTimer = localStorage.getItem('timer') ?? timerValue;
  const initialProgress = localStorage.getItem('progress') ?? 0;
  const timeoutId = useRef(null);
  const [ timer, setTimer ] = useState(initialTimer);
  const [ progress, setProgress ] = useState(initialProgress);

  const countTimer = useCallback(() => {
    if (timer <= 0) {
      localStorage.clear('timer');
      localStorage.clear('progress');
      window.dispatchEvent(new Event('timeOut'));
    } else {
      setTimer(timer - 1);
      localStorage.setItem('timer', timer);
    }
  }, [ timer ]);

  useEffect(() => {
    setProgress(((timerValue - timer) / timerValue) * 100);
    localStorage.setItem('progress', progress);

    timeoutId.current = window.setTimeout(countTimer, 1000);
    return () => window.clearTimeout(timeoutId.current);
  }, [ timer, countTimer ]);

  return { progress, timer };
};
