import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import { mkNotification } from '../../../../lib/mkNotification';
import QuestionsService from '../services/questionsService';



const initialState = {
  questionsListData: [],
  loadingQuestionsList: false,
};

const administrationService = new QuestionsService(Api);

export const getQuestionsListData = createAsyncThunk(
  'questions/getQuestionsListData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await administrationService.getQuestionsListData();

      if (isEmpty(response)) {
        mkNotification('info', 'Нет данных для отображения списка вопросов.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const deleteQuestion = createAsyncThunk(
  'questions/deleteQuestion',
  async (questionId) => {
    const response = await administrationService.deleteQuestion(questionId);

    return response.data;
  },
);

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    resetQuestionsListData: (state) => {
      state.questionsListData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionsListData.pending, (state) => {
        state.loadingQuestionsList = true;
      })
      .addCase(getQuestionsListData.fulfilled, (state, action) => {
        state.loadingQuestionsList = false;
        state.questionsListData = action.payload;
      })
      .addCase(getQuestionsListData.rejected, (state) => {
        state.loadingQuestionsList = false;
      });

    builder
      .addCase(deleteQuestion.pending, (state) => {
        state.loadingQuestionsList = true;
      })
      .addCase(deleteQuestion.fulfilled, (state) => {
        state.loadingQuestionsList = false;
        mkNotification('success', 'Вопрос успешно удален.');
      })
      .addCase(deleteQuestion.rejected, (state) => {
        state.loadingQuestionsList = false;
      });
  },
});

// actions
export const { resetQuestionsListData } = questionsSlice.actions;

// selectors
export const selectQuestionsListData = (state) => state.questions.questionsListData;
export const selectQuestionsListLoading = (state) => state.questions.loadingQuestionsList;

// reducer
export default questionsSlice.reducer;
