export default class AdministrationService {
  constructor (Api) {
    this.api = Api;
  }

  getUsersListData () {
    return this.api.get('users');
  }

  createUser (values) {
    return this.api.post('users', values);
  }

  deleteUser (userId) {
    return this.api.delete(`users/${userId}`);
  }
}
