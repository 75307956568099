import { Button, Result } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';



const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="404"
      title="404"
      subTitle="Уппс, страница не найдена."
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate(-1);
          }}
        >
          Вернуться назад
        </Button>
      }
    />
  );
};

export default AccessDenied;
