import React from 'react';
import { Badge, Card, Descriptions, Tag } from 'antd';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import {
  USER_STATUS_COLOR,
  USER_STATUS_COLOR_TRANSLATIONS,
} from '../../domains/administration/PageAdministration/PageAdministration.const';



const UserDetailsCard = ({ data }) => {
  return (
    <Card title='Детали пользователя'>
      <Descriptions column={1}>
        <Descriptions.Item label="Ф.И.О.">{data.name || '---'}</Descriptions.Item>
        <Descriptions.Item label="Почта">
          <a href={`mailto:${data.email}`}>{data.email}</a>
        </Descriptions.Item>
        <Descriptions.Item label="Дата создания">
          <Tag color="blue">
            {format(new Date(data.created_at), 'dd.MM.yyyy HH:mm') || '---'}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Дата логина">
          <Tag color="purple">
            {format(new Date(data.last_login), 'dd.MM.yyyy HH:mm') || '---'}
          </Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Кол-во попыток">
          {data?.tryes.length ?? '---'}
        </Descriptions.Item>
        <Descriptions.Item label="Статус теста">
          <Badge color={USER_STATUS_COLOR(data.status)} text={USER_STATUS_COLOR_TRANSLATIONS(data.status)} />
        </Descriptions.Item>

      </Descriptions>
    </Card>
  );
};


UserDetailsCard.propTypes = {
  data: PropTypes.object,
};

UserDetailsCard.defaultProps = {
  data: {},
};

export default UserDetailsCard;
