export const USER_ROLES = { ADMIN: 'admin', USER: 'user' };

export const ROLES = [
  { label: 'Администратор', value: 'admin' },
  { label: 'Пользователь', value: 'user' },
];


export const currentRole = JSON.parse(localStorage.getItem('user'))?.user?.role;
export const hasAdminRights = currentRole === USER_ROLES.ADMIN;
