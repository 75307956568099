import React from 'react';
import { Field, Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik, FieldArray } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Checkbox, Col, Divider, Popconfirm, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { DeleteOutlined } from '@ant-design/icons';
import { RiAddFill } from 'react-icons/ri';
import { openWarningModal } from './FormCreateEditQuestion.const';
import Yup from '../../../../vendor/yup';



const FormCreateEditQuestionButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const FormCreateEditQuestionButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;


const validationSchema = Yup.object().shape({
  question: Yup.string()
    .min(3, 'Минимум 3 символа', { limit: 3 })
    .max(255, 'Максимум 255 символов', { limit: 255 })
    .required('Обязательное поле'),
  answers: Yup.array().of(
    Yup.object().shape({
      text: Yup.string()
        .min(10, 'Минимум 10 символов')
        .max(255, 'Максимум 255 символов')
        .required('Обязательное поле'),
    }),
  ),
});


const FormCreateEditQuestion = ({
  onSubmit,
  isCreate,
  initialValues,
  isSubmitting,
  formErrors,
  allowEdit,
  setAllowEdit,
}) => {
  const navigate = useNavigate();
  const isViewMode = !isCreate && !allowEdit;

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={initialValues}
      initialErrors={formErrors}
      onSubmit={(values) => {
        const isInvalidCheckboxes = values.answers.findIndex((item) => !!item.is_true) === -1;

        if (isInvalidCheckboxes) {
          openWarningModal();
        } else {
          onSubmit(values);
        }
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, resetForm, dirty, values } = props;
        const hasDeleteBtn = values.answers.length > 1;

        return (
          <Form layout="vertical" className='kt-w-100'>
            <FormItem
              className='ant-form-item-col'
              name="question"
              label="Вопрос:"
              required
            >
              <Input.TextArea
                name='question'
                placeholder='Введите вопрос'
                rows={3}
                maxLength={255}
                disabled={isViewMode}
              />
            </FormItem>

            <Divider orientation='left'>Варианты ответов</Divider>
            <FieldArray
              name="answers"
              value={values.answers}
            >
              {({ remove, push }) => {
                return (
                  <>
                    {!isEmpty(values?.answers) && values.answers.map((group, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Field name={`answers.${index}`} key={index}>
                        {({ field: { name, value }, form: { setFieldValue } }) => {
                          return (
                            <Row gutter={16}>
                              <Col xs={2} sm={1} className='flex-column-center'>
                                <FormItem name={`${name}.is_true`}>
                                  <Checkbox
                                    className='checkbox-large'
                                    checked={!!value.is_true}
                                    disabled={isViewMode}
                                    onChange={(event) => {
                                      setFieldValue(`${name}.is_true`, event.target.checked);
                                    }}
                                  />
                                </FormItem>
                              </Col>
                              <Col xs={22} md={hasDeleteBtn && !isViewMode ? 22 : 23}>
                                <FormItem
                                  className='ant-form-item-col'
                                  name={`${name}.text`}
                                  label={`Вариант #${index + 1}:`}
                                  required
                                >
                                  <Input.TextArea
                                    rows={2}
                                    name={`${name}.text`}
                                    disabled={isViewMode}
                                    placeholder='Введите ответ'
                                    onChange={(event) => {
                                      setFieldValue(`${name}.text`, event.target.value);
                                    }}
                                  />
                                </FormItem>
                              </Col>
                              {hasDeleteBtn && !isViewMode && (
                                <Col xs={1} className='flex-column-center'>
                                  <Popconfirm
                                    title="Вы уверены что хотите удалить этот ответ?"
                                    placement="topRight"
                                    okText="Да"
                                    cancelText="Отмена"
                                    onConfirm={() => remove(index)}
                                  >
                                    <Button
                                      size='small'
                                      danger
                                      icon={<DeleteOutlined />}
                                    />
                                  </Popconfirm>
                                </Col>
                              )}
                            </Row>
                          );
                        }}
                      </Field>
                    )) }
                    <Row align='end'>
                      <Button
                        type='primary'
                        disabled={isViewMode}
                        icon={<RiAddFill />}
                        onClick={() => push({ text: '', is_true: false })}
                      >
                        Добавить ответ
                      </Button>
                    </Row>
                  </>
                );
              }}
            </FieldArray>

            <FormCreateEditQuestionButtonsRow>
              <FormCreateEditQuestionButtonWr>
                <Button
                  className='kt-w-100'
                  type='secondary'
                  onClick={() => {
                    if (allowEdit) {
                      setAllowEdit(false);
                    } else {
                      navigate(-1);
                    }
                    resetForm();
                  }}
                >
                  Отмена
                </Button>
              </FormCreateEditQuestionButtonWr>
              <FormCreateEditQuestionButtonWr>
                {isViewMode ? (
                  <Button
                    type='primary'
                    onClick={() => {
                      setAllowEdit(!allowEdit);
                    }}
                  >
                    Редактировать
                  </Button>
                ) : (
                  <SubmitButton
                    loading={isSubmitting}
                    disabled={!isValid || !dirty}
                  >
                    {isCreate ? 'Создать' : 'Сохранить'}
                  </SubmitButton>
                )}
              </FormCreateEditQuestionButtonWr>
            </FormCreateEditQuestionButtonsRow>
          </Form>
        );
      }}
    </Formik>
  );
};

FormCreateEditQuestion.propTypes = {
  isCreate: PropTypes.bool.isRequired,
  allowEdit: PropTypes.bool,
  setAllowEdit: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  values: PropTypes.shape({
    answers: PropTypes.array,
  }),
  formErrors: PropTypes.array,
  isSubmitting: PropTypes.bool.isRequired,
};

FormCreateEditQuestion.defaultProps = {
  formErrors: [],
  allowEdit: false,
  values: {
    answers: [ { text: '', is_true: false } ],
  },
};

export default FormCreateEditQuestion;
