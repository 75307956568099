import React, { useEffect, useState } from 'react';
import { Breadcrumb, Card, Spin } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormCreateEditQuestion from '../PageQuestions/FormCreateEditQuestion';
import urlPageQuestions from '../../../urls/urlPageQuestions';
import {
  createQuestion,
  getQuestionDetails,
  updateQuestion,
  selectFormErrors,
  selectFormLoading,
  selectQuestionDetailsData,
  selectQuestionDetailsLoading,
} from './feature/questionDetailsSlice';



const PageCreateEditQuestion = () => {
  const { pathname } = useLocation();
  const { questionId } = useParams();
  const dispatch = useDispatch();
  const [ allowEdit, setAllowEdit ] = useState(false);
  const isCreateQuestionPage = pathname.endsWith('create');

  const questionDetails = useSelector(selectQuestionDetailsData);
  const loadingQuestionDetails = useSelector(selectQuestionDetailsLoading);
  const loadingForm = useSelector(selectFormLoading);
  const formErrors = useSelector(selectFormErrors);

  useEffect(() => {
    if (!isCreateQuestionPage) {
      dispatch(getQuestionDetails(questionId));
    }
  }, []);

  const handleSubmitForm = (values) => {
    if (isCreateQuestionPage) {
      dispatch(createQuestion(values));
    } else {
      dispatch(updateQuestion({ questionId, values }));
    }
  };

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={urlPageQuestions()}>
            Список вопросов
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          {!isCreateQuestionPage ? 'Редактирование' : 'Создание'}
        </Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={loadingForm || loadingQuestionDetails} tip="Загрузка...">
        <Card>
          <FormCreateEditQuestion
            allowEdit={allowEdit}
            setAllowEdit={setAllowEdit}
            isCreate={isCreateQuestionPage}
            initialValues={isCreateQuestionPage ?
              { answers: [
                { text: '', is_true: false },
              ] } : questionDetails}
            formErrors={formErrors}
            onSubmit={handleSubmitForm}
            isSubmitting={loadingForm}
          />
        </Card>
      </Spin>
    </div>
  );
};


export default PageCreateEditQuestion;
