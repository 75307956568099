import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import LayoutInner from '../Layout/LayoutInner';
import { currentRole } from '../../../const/system';
import AccessDenied from '../AccessDenied';



const PrivateRoute = ({ roles, redirectPath }) => {
  const { isAuthenticated } = JSON.parse(localStorage.getItem('user'));
  const hasRights = roles.includes(currentRole);

  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isAuthenticated && !hasRights) {
    return <AccessDenied />;
  }

  return (
    <LayoutInner>
      <Outlet />
    </LayoutInner>
  );
};


PrivateRoute.propTypes = {
  roles: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]).isRequired,
  redirectPath: PropTypes.string,
};

PrivateRoute.defaultProps = {
  redirectPath: '/',
};

export default PrivateRoute;
