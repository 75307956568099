import React from 'react';
import { useGoogleLogout } from 'react-google-login';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import Config from '../../../config/index';
import { logoutUser } from '../../../domains/login/feature/authSlice';



const LogoutButton = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onLogoutSuccess = () => {
    dispatch(logoutUser());
    sessionStorage.removeItem('auth');
    navigate('/');
  };

  const { signOut } = useGoogleLogout({
    clientId: Config.GOOGLE_CLIENT_ID,
    onLogoutSuccess,
  });

  return (
    <Button
      onClick={signOut}
      type='link'
      icon={<RiLogoutBoxRLine fill='#56A9A2' className='kt-mr-5' size={18} />}
    >
      Выход
    </Button>
  );
};

export default LogoutButton;
