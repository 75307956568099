import React from 'react';
import { format } from 'date-fns';
import { AiOutlineDelete } from 'react-icons/ai';
import { Badge, Button, Popconfirm, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import urlPageUserDetails from '../../../urls/urlPageUserDetails';



export const USER_STATUS_MAP = {
  NOT_STARTED: 'pending',
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
};

export const USER_STATUS_COLOR_TRANSLATIONS = (status) => {
  switch (status) {
    case USER_STATUS_MAP.NOT_STARTED: {
      return 'В ожидании';
    }
    case USER_STATUS_MAP.IN_PROGRESS: {
      return 'В процессе';
    }
    case USER_STATUS_MAP.FINISHED: {
      return 'Выполнен';
    }
    default: {
      return '---';
    }
  }
};

export const USER_STATUS_COLOR = (status) => {
  switch (status) {
    case 'in_progress': {
      return 'green';
    }
    case 'finished': {
      return 'geekblue';
    }
    default: {
      return 'red';
    }
  }
};

export const getUsersListTableColumns = (handleDeleteUser) => [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
    width: '7%',
    sorter: (_a, _b) => _a.id - _b.id,
    render: (value) => <div>{value}</div>,
  },
  {
    title: 'Имя',
    align: 'center',
    render: (item) => <div>{item.name || '---'}</div>,
  },
  {
    title: 'Почта',
    dataIndex: 'email',
    align: 'center',
    render: (value) => <a href={`mailto:${value}`}>{value}</a>,
  },
  {
    title: 'Попыток',
    dataIndex: 'tryes_amount',
    align: 'center',
    sorter: (_a, _b) => _a.tryes_amount - _b.tryes_amount,
    render: (value) => <div>{value ?? '---'}</div>,
  },
  {
    title: 'Статус теста',
    dataIndex: 'status',
    align: 'center',
    render: (value) => {
      return (
        <Badge color={USER_STATUS_COLOR(value)} text={USER_STATUS_COLOR_TRANSLATIONS(value)} />
      );
    },
  },

  {
    title: 'Дата регистрации',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => <>{format(new Date(value), 'dd.MM.yyyy HH:mm')}</>,
    sorter: (_a, _b) => _a.created_at - _b.created_at,
  },
  {
    title: 'Последний логин',
    dataIndex: 'last_login',
    align: 'center',
    render: (value) => <div>{value ? format(new Date(value), 'dd.MM.yyyy HH:mm') : '---'}</div>,
    sorter: (_a, _b) => _a.last_login - _b.last_login,
  },
  {
    title: 'Действие',
    key: 'action',
    align: 'center',
    width: '10%',
    render: (item) => {
      return (
        <Row className="da-h-100" align="middle" justify="center">
          <Popconfirm
            title="Вы уверены что хотите удалить данного пользователя?"
            placement="topRight"
            okText="Да"
            cancelText="Отмена"
            onConfirm={() => handleDeleteUser(item.id)}
          >
            <Tooltip placement="top" title='Удалить пользователя'>
              <Button type='primary' danger size='small' className='kt-mr-5'>
                <AiOutlineDelete size={18} />
              </Button>
            </Tooltip>
          </Popconfirm>
          <Tooltip placement="top" title='Детали пользователя'>
            <Button type='primary' size='small'>
              <Link to={urlPageUserDetails({ userId: item.id })}>
                <EyeOutlined />
              </Link>
            </Button>
          </Tooltip>
        </Row>
      );
    },
  },
];
