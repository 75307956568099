export default class QuestionsService {
  constructor (Api) {
    this.api = Api;
  }

  getQuestionsListData () {
    return this.api.get('questions');
  }

  deleteQuestion (questionId) {
    return this.api.delete(`questions/${questionId}`);
  }
}
