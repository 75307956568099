import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import { mkNotification } from '../../../../lib/mkNotification';
import NotificationsService from '../services/notificationsService';



const initialState = {
  notificationsData: [],
  loadingNotifications: false,
  loadingMarkAsRead: false,
};

const notificationsService = new NotificationsService(Api);

export const getNotificationsData = createAsyncThunk(
  'notifications/getNotificationsData',
  async (_, { rejectWithValue }) => {
    try {
      const response = await notificationsService.getNotificationsData();

      if (isEmpty(response)) {
        mkNotification('info', 'Нет данных для отображения уведомлений.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const markAsReadNotification = createAsyncThunk(
  'notifications/markAsRead',
  async ({ notificationId }, { rejectWithValue }) => {
    try {
      const response = await notificationsService.markAsRead({ notificationId });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetNotificationsData: (state) => {
      state.notificationsData = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsData.pending, (state) => {
        state.loadingNotifications = true;
      })
      .addCase(getNotificationsData.fulfilled, (state, action) => {
        state.loadingNotifications = false;
        state.notificationsData = action.payload;
      })
      .addCase(getNotificationsData.rejected, (state) => {
        state.loadingNotifications = false;
      });

    builder
      .addCase(markAsReadNotification.pending, (state) => {
        state.loadingMarkAsRead = true;
      })
      .addCase(markAsReadNotification.fulfilled, (state, action) => {
        state.loadingMarkAsRead = false;
        state.notificationsData = state.notificationsData.filter((item) => item.id !== action.payload); //action.meta.arg.notificationId
      })
      .addCase(markAsReadNotification.rejected, (state) => {
        state.loadingMarkAsRead = false;
      });
  },
});

// actions
export const { resetNotificationsData } = notificationsSlice.actions;

// selectors
export const selectNotificationsLoading = (state) => state.notifications.loadingNotifications;
export const selectNotificationsData = (state) => state.notifications.notificationsData;
export const selectMarkAsReadLoading = (state) => state.notifications.loadingMarkAsRead;

// reducer
export default notificationsSlice.reducer;
