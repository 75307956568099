import React, { useEffect } from 'react';
import { Breadcrumb, Button, Card, Col, Row, Table } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionsListTableColumns } from './PageQuestions.const';
import urlPageCreateQuestion from '../../../urls/urlPageCreateQuestion';
import {
  deleteQuestion,
  getQuestionsListData,
  resetQuestionsListData,
  selectQuestionsListData,
  selectQuestionsListLoading,
} from './feature/questionsSlice';



const PageQuestions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const questionsList = useSelector(selectQuestionsListData);
  const loadingQuestionsList = useSelector(selectQuestionsListLoading);

  useEffect(() => {
    dispatch(getQuestionsListData());

    return () => {
      dispatch(resetQuestionsListData());
    };
  }, []);

  const handleDeleteQuestion = (questionId) => {
    dispatch(deleteQuestion(questionId)).then(() => {
      dispatch(getQuestionsListData());
    });
  };

  return (
    <div>
      <Col span={24}>
        <Row justify="space-between">
          <Breadcrumb separator=">">
            <Breadcrumb.Item>
              Список вопросов
            </Breadcrumb.Item>
          </Breadcrumb>

          <div>
            <Button
              size='middle'
              type="primary"
              onClick={() => {
                navigate(urlPageCreateQuestion());
              }}
            >
              Добавить вопрос
            </Button>
          </div>
        </Row>
      </Col>

      <Card>
        <Table
          sticky
          rowKey="id"
          dataSource={questionsList}
          loading={loadingQuestionsList}
          columns={getQuestionsListTableColumns(handleDeleteQuestion)}
        />
      </Card>
    </div>
  );
};


export default PageQuestions;
