import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Col, Modal, Row, Spin } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import urlPageAdministration from '../../../urls/urlPageAdministration';
import UserDetailsCard from '../../../components/UserDetailsCard';
import {
  getUserDetailsData,
  resetUserDetailsData,
  selectUserDetailsData,
  selectUserDetailsLoading,
} from './feature/userDetailsSlice';
import { updateTestStatus } from '../../logical-test/feature/logicalTestSlice';
import { USER_STATUS_MAP } from '../PageAdministration/PageAdministration.const';



const { confirm } = Modal;


const PageUserDetails = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const userDetails = useSelector(selectUserDetailsData);
  const userDetailsLoading = useSelector(selectUserDetailsLoading);
  const [ isRefreshDisabled, setDisabledRefreshUser ] = useState(false);

  const userTryes = userDetails?.tryes.length ?? 0;

  useEffect(() => {
    dispatch(getUserDetailsData(userId));

    return () => {
      dispatch(resetUserDetailsData());
    };
  }, []);

  const handleRefreshUserAnswers = (userId) => {
    dispatch(updateTestStatus({ userId, status: USER_STATUS_MAP.NOT_STARTED }));
    setDisabledRefreshUser(true);
  };

  const showConfirmation = () => {
    confirm({
      title: 'Внимание!',
      icon: <ExclamationCircleOutlined />,
      content: 'Вы уверены что хотите разрешить пользователю пройти тест повторно?',
      okText: 'Да',
      cancelText: 'Отменить',

      onOk () {
        handleRefreshUserAnswers(userId);
      },
    });
  };

  return (
    <Col span={24}>
      <Row justify="space-between">
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <Link to={urlPageAdministration()}>
              Управление
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            Детали пользователя
          </Breadcrumb.Item>
        </Breadcrumb>

        <div>
          <Button
            size='middle'
            disabled={isEmpty(userDetails) || userDetails.status === USER_STATUS_MAP.NOT_STARTED || isRefreshDisabled}
            onClick={showConfirmation}
          >
            Допустить повторно
          </Button>
        </div>

        <Spin spinning={userDetailsLoading} tip="Загрузка...">
          <Row gutter={[ 16, 16 ]}>
            <Col xs={24} md={7}>
              <UserDetailsCard data={userDetails} />
            </Col>
            <Col xs={24} md={17}>

              <Card title='Результат теста'>
                {!isEmpty(userDetails?.tryes) ? userDetails.tryes.map((item, index) => {
                  return (
                    <Row key={item[index]} className='kt-mb-15'>
                      <Card
                        className='colored-card-head kt-w-100'
                        title={userTryes > 1 ? `Попытка #${index + 1}` : ''}
                      >
                        <Col>
                          <Row>
                            <h3>Верных ответов: &nbsp;&nbsp;</h3>
                            <h3 className='text-secondary'>
                              {`${item?.right_answers ?? '---'} / ${item?.total_questions ?? '---'}`}
                            </h3>
                          </Row>
                          <Row>
                            <h3>Процент верно отвеченных вопросов: &nbsp;&nbsp;</h3>
                            <h3 className='text-secondary'>
                              {`${((item.right_answers / item.total_questions) * 100).toFixed(2)} %`}
                            </h3>
                          </Row>
                          <Row>
                            <h3>Затраченное время: &nbsp;&nbsp;</h3>
                            <h3 className='text-secondary'>
                              {`${item?.elapsed_time?.minutes ?? '---'} мин`}
                            &nbsp;
                              {`${item?.elapsed_time?.seconds ?? '---'} сек`}
                            </h3>
                          </Row>
                        </Col>
                      </Card>
                    </Row>
                  );
                }) : (
                  <div>Пользователь еще не прошел тест.</div>
                )}
              </Card>
            </Col>
          </Row>
        </Spin>
      </Row>
    </Col>
  );
};

export default PageUserDetails;
