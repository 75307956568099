import { Avatar, Button, Image, List, Spin } from 'antd';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import urlPageUserDetails from '../../../../urls/urlPageUserDetails';
import { markAsReadNotification } from '../feature/notificationsSlice';



const AdminNotificationDropdown = ({ data, isLoading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMarkAsRead = (notificationId, userId) => {
    dispatch(markAsReadNotification({ notificationId }));
    navigate(urlPageUserDetails({ userId }));
  };

  return (
    <Spin spinning={isLoading}>
      <List
        className="notification-dropdown"
        style={!isEmpty(data) ? { width: '300px' } : {}}
        itemLayout="horizontal"
        dataSource={data}
        locale={{ emptyText: 'Уведомлений нет' }}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar
                src={
                  <Image
                    referrerPolicy="no-referrer"
                    src={item.avatar || null}
                    style={{ width: 40, height: 40 }}
                    preview={false}
                  />
                }
              />}
              title={item.username}
              description={
                <>
                  <div>Юзер завершил тест.</div>
                  <Button
                    type='link'
                    onClick={() => {
                      handleMarkAsRead(item.id, item.user_id);
                    }}
                  >
                    Посмотреть результат
                  </Button>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Spin>
  );
};

AdminNotificationDropdown.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default AdminNotificationDropdown;
