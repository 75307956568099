import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import Api from '../../../../services/Api/Api';
import { mkNotification } from '../../../../lib/mkNotification';
import QuestionDetailsService from '../services/questionDetailsService';



const initialState = {
  questionDetailsData: {},
  loadingQuestionDetails: false,
  formLoading: false,
  formErrors: {},
};

const questionDetailsService = new QuestionDetailsService(Api);

export const getQuestionDetails = createAsyncThunk(
  'questionDetails/getQuestionDetails',
  async (questionId, { rejectWithValue }) => {
    try {
      const response = await questionDetailsService.getQuestionDetails(questionId);

      if (isEmpty(response)) {
        mkNotification('info', 'Нет данных для отображения вопроса.');
      }
      return isEmpty(response) ? [] : response;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const createQuestion = createAsyncThunk(
  'questionDetails/createQuestion',
  async (values, { rejectWithValue }) => {
    try {
      const response = await questionDetailsService.createQuestion(values);

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateQuestion = createAsyncThunk(
  'questionDetails/updateQuestion',
  async ({ questionId, values }, { rejectWithValue }) => {
    try {
      const response = await questionDetailsService.updateQuestion({ questionId, values });

      return response.data;
    } catch (err) {
      if (!err.response) {
        throw new Error(`Oops something went wrong: ${err}`);
      }

      return rejectWithValue(err.response.data);
    }
  },
);


export const questionDetailsSlice = createSlice({
  name: 'questionDetails',
  initialState,
  reducers: {
    resetQuestionDetailsData: (state) => {
      state.questionDetailsData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuestionDetails.pending, (state) => {
        state.loadingQuestionDetails = true;
      })
      .addCase(getQuestionDetails.fulfilled, (state, action) => {
        state.loadingQuestionDetails = false;
        state.questionDetailsData = action.payload;
      })
      .addCase(getQuestionDetails.rejected, (state) => {
        state.loadingQuestionDetails = false;
      });

    builder
      .addCase(createQuestion.pending, (state) => {
        state.formLoading = true;
        state.formErrors = {};
      })
      .addCase(createQuestion.fulfilled, (state) => {
        state.formLoading = false;
        mkNotification('success', 'Вопрос успешно создан.');
      })
      .addCase(createQuestion.rejected, (state, action) => {
        state.formLoading = false;
        state.formErrors = action.payload.errors;
      });

    builder
      .addCase(updateQuestion.pending, (state) => {
        state.formLoading = true;
        state.formErrors = {};
      })
      .addCase(updateQuestion.fulfilled, (state) => {
        state.formLoading = false;
        mkNotification('success', 'Вопрос успешно обновлен.');
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.formLoading = false;
        state.formErrors = action.payload.errors;
      });
  },
});

// actions
export const { resetQuestionDetailsData } = questionDetailsSlice.actions;

// selectors
export const selectQuestionDetailsData = (state) => state.questionDetails.questionDetailsData;
export const selectQuestionDetailsLoading = (state) => state.questionDetails.loadingQuestionDetails;
export const selectFormLoading = (state) => state.questionDetails.formLoading;
export const selectFormErrors = (state) => state.questionDetails.formErrors;

// reducer
export default questionDetailsSlice.reducer;
