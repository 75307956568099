import React from 'react';
import { Card, Avatar, Image, Button } from 'antd';
import PropTypes from 'prop-types';
import TestIcon from '../../assets/images/logical_test_icon.svg';



const TestPreview = ({ handleStartTest }) => {
  return (
    <Card>
      <div className='flex-row-center'>
        <Avatar
          className='kt-mb-5'
          size={140}
          src={
            <Image
              referrerPolicy="no-referrer"
              src={TestIcon}
              style={{ width: 140, height: 140 }}
              preview={false}
            />
          }
        />
        <h1>Тест на логическое мышление</h1>

        <Button
          className='kt-mb-15'
          size='large'
          onClick={() => {
            handleStartTest();
          }}
        >
          Начать тест
        </Button>
      </div>
      <div className='flex-row-center lg-font'>
        <p>
          Весь наш мир и все наши действия подчинены причинно‐следственным связям.
          Видеть их и понимать – очень важно для каждого. Для этого существует логика, протестировать которую можно очень легко и быстро.
          Ведь она основана на базовых понятиях, которые многие люди чувствуют просто подсознательно.
        </p>
        <p className='kt-w-100'>
          Различные тесты на логику с ответами после прохождения приобрели довольно большую популярность не только среди молодежи,
          но и у взрослого поколения. И это легко объясняется:
          <ul>
            <li>Обычно они довольно короткие (например, 30 вопросов).</li>
            <li>Часто забавные (про всяких там шмурдиков, грымзиков, уузку и т.д.), что помогает абстрагироваться от повседневных стереотипов.</li>
            <li>Дают возможность подняться в глазах друзей (мы ведь любим делиться своими достижениями, высокими результатами, уникальностью).</li>
          </ul>
          Учтите, что тест только на первый взгляд простой - на самом деле он довольно сложный. Готовы узнать насколько у вас развито логическое мышление? Приступим!
        </p>
      </div>
    </Card>
  );
};

TestPreview.propTypes = {
  handleStartTest: PropTypes.func.isRequired,
};

export default TestPreview;
