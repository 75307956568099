export default class NotificationsService {
  constructor (Api) {
    this.api = Api;
  }

  getNotificationsData () {
    return this.api.get('notifications');
  }

  markAsRead ({ notificationId }) {
    return this.api.post(`notifications/${notificationId}`);
  }
}
