import React, { useEffect, useState } from 'react';
import { Card, Image, Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import differenceBy from 'lodash/differenceBy';
import { useDispatch, useSelector } from 'react-redux';
import FormQuestions from './FormQuestions';
import TestDoneImg from '../../assets/images/test_done.svg';
import TestPreview from '../../components/TestPreview';
import CountdownTimer from '../../components/CountdownTimer';
import TimeoutImg from '../../assets/images/timeout_img.svg';
import {
  getTestQuestionsList,
  updateTestStatus,
  publishUserAnswers,
  getUserTestStatus,
  selectTestAnswersListLoading,
  selectTestQuestionsListData,
  selectTestQuestionsListLoading,
  selectUserTestStatus,
} from './feature/logicalTestSlice';
import { USER_STATUS_MAP } from '../administration/PageAdministration/PageAdministration.const';



const PageLogicalTest = () => {
  const dispatch = useDispatch();
  const testQuestionsList = useSelector(selectTestQuestionsListData);
  const loadingTestQuestions = useSelector(selectTestQuestionsListLoading);
  const isPublishingUserAnswers = useSelector(selectTestAnswersListLoading);
  const testStatus = useSelector(selectUserTestStatus);

  const userID = JSON.parse(localStorage.getItem('user')).user.id;
  const userAnswers = JSON.parse(localStorage.getItem('storedAnswers'));
  const storedAnswers = !isEmpty(userAnswers) ? userAnswers : [];
  const initialQuestions = differenceBy(testQuestionsList, storedAnswers, 'id');
  const [ questionsLeft, setQuestionsLeft ] = useState(initialQuestions);
  const [ isTimesUp, setTestTimeOut ] = useState(false);


  useEffect(() => {
    dispatch(getUserTestStatus(userID));

    if (questionsLeft.length === 0) {
      dispatch(updateTestStatus({ userId: userID, status: USER_STATUS_MAP.FINISHED })).then(() => {
        dispatch(publishUserAnswers({ answers: userAnswers, userId: userID }));
      });
    }
  }, [ questionsLeft.length === 0 ]);


  window.addEventListener('timeOut', () => {
    setTestTimeOut(true);
  });

  const addAnswer = (items) => {
    storedAnswers.push(items);
    return storedAnswers;
  };

  const handleStartTest = () => {
    dispatch(updateTestStatus({ userId: userID, status: USER_STATUS_MAP.IN_PROGRESS })).then(() => {
      dispatch(getTestQuestionsList());
    });
  };

  const handleSubmit = (values) => {
    const data = { id: values.id, answer_id: values.user_answer_id };

    localStorage.setItem('storedAnswers', JSON.stringify(addAnswer(data)));
    setQuestionsLeft(differenceBy(testQuestionsList, storedAnswers, 'id'));
  };

  return (
    <div>
      {isEmpty(testStatus) || testStatus === USER_STATUS_MAP.NOT_STARTED ? (
        <TestPreview handleStartTest={handleStartTest} />
      ) : (
        <Card>
          {!isEmpty(questionsLeft) ? (
            <div>
              {!isTimesUp ? (
                <Spin spinning={loadingTestQuestions || isPublishingUserAnswers} tip="Загрузка...">
                  <h1>
                    <span style={{ color: '#1a1a226b' }}>{testQuestionsList.length - questionsLeft.length + 1}</span>
                    /
                    <span>{testQuestionsList.length}</span>
                  </h1>
                  <FormQuestions
                    isSubmitting={isPublishingUserAnswers}
                    onSubmit={handleSubmit}
                    isLastQuestion={questionsLeft.length === 1}
                    initialValues={questionsLeft[0]}
                  />
                  <CountdownTimer timeInSeconds={1800} />
                </Spin>
              ) : (
                <div className='flex-row-center'>
                  <Image
                    style={{ width: '45vh' }}
                    referrerPolicy="no-referrer"
                    src={TimeoutImg}
                    preview={false}
                  />
                  <h1>Уупс. Время вышло.</h1>
                  <h3 className='kt-mb-15'>Вы не успели пройти тест за отведенный период. Обратитесь к HR специалисту.</h3>
                </div>
              )}
            </div>
          ) : (
            <div className='flex-row-center'>
              <Image
                style={{ width: '45vh' }}
                referrerPolicy="no-referrer"
                src={TestDoneImg}
                preview={false}
              />
              <h1>Поздравляем! Тест завершен.</h1>
              <h3 className='kt-mb-15'>Результаты теста можно узнать у HR специалиста.</h3>
            </div>
          )}
        </Card>
      )}
    </div>
  );
};


export default PageLogicalTest;
