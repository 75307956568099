import React from 'react';
import { Progress } from 'antd';
import { addSeconds, intervalToDuration } from 'date-fns';
import PropTypes from 'prop-types';
import { useCountdown } from '../../hooks/useCountdown';



const CountdownTimer = ({ timeInSeconds }) => {
  const startDate = JSON.parse(localStorage.getItem('start_date'));
  const { progress, timer } = useCountdown(timeInSeconds);

  const duration = intervalToDuration({ start: new Date(startDate), end: addSeconds(new Date(startDate), timer) });
  const zeroPad = (num) => String(num).padStart(2, '0');
  const dateTimeRemaining = `${zeroPad(duration.minutes)}:${zeroPad(duration.seconds)}`;


  return (
    <div className='flex'>
      <Progress
        className='progress-grey'
        showInfo={false}
        percent={progress}
      />
      <span className='countdown-time'>{dateTimeRemaining}</span>
    </div>
  );
};

CountdownTimer.propTypes = {
  timeInSeconds: PropTypes.number.isRequired,
};

export default CountdownTimer;
