import React, { useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Avatar, Badge, Col, Dropdown, Image, Layout, Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { IoMdNotifications } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import MenuLogo from '../../../assets/images/x_flow_white_logo.svg';
import { MenuItems } from './LayoutInner.const';
import UserDropdown from './UserDropdown';
import AdminNotificationDropdown from './AdminNotificationDropdown';
import { currentRole, hasAdminRights } from '../../../const/system';
import { getNotificationsData, selectNotificationsData, selectNotificationsLoading } from './feature/notificationsSlice';



const { Header, Content, Footer } = Layout;


const LayoutInner = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const authData = JSON.parse(sessionStorage.getItem('auth'));

  const notificationsData = useSelector(selectNotificationsData);
  const notificationsLoading = useSelector(selectNotificationsLoading);

  useEffect(() => {
    if (hasAdminRights) {
      dispatch(getNotificationsData());

      const notificationsIntervalId = setInterval(() => {
        return dispatch(getNotificationsData());
      }, 60000); //1min

      return () => {
        clearInterval(notificationsIntervalId);
      };
    }
    return null;
  }, []);


  return (
    <Layout>
      <Header className="header">
        <div className="header_logo">
          <Image src={MenuLogo} preview={false} />
        </div>
        <Menu
          theme='dark'
          mode="horizontal"
          selectedKeys={[ pathname.substring(1) ]}
        >
          {!isEmpty(MenuItems) && MenuItems.map((item) => {
            if (item?.roles.includes(currentRole)) {
              return (
                <Menu.Item key={item.key} icon={item.icon}>
                  <Link to={item.navLink}>{item.label}</Link>
                </Menu.Item>
              );
            }
            return null;
          })}
        </Menu>

        {hasAdminRights && (
          <Dropdown
            placement="bottomLeft"
            overlay={<AdminNotificationDropdown data={notificationsData} isLoading={notificationsLoading} />}
          >
            <Badge dot={!isEmpty(notificationsData)} className='kt-mr-15' size='small'>
              <Avatar
                className='pointer flex-row-center'
                size='large'
                shape="circle"
                src={
                  <IoMdNotifications size='30px' fill='rgba(255, 255, 255, 0.65)' />
                }
              />
            </Badge>
          </Dropdown>
        )}

        <Col>
          <Dropdown overlay={<UserDropdown />} placement="bottomLeft">
            <Col onClick={(ev) => ev.preventDefault()}>
              <Avatar
                className='pointer'
                size={40}
                src={
                  <Image
                    referrerPolicy="no-referrer"
                    src={authData.profileObj.imageUrl || null}
                    style={{ width: 40, height: 40 }}
                    preview={false}
                  />
                }
              />
            </Col>
          </Dropdown>
        </Col>
      </Header>
      <Content style={{ padding: '20px 30px' }}>
        {children}
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <a
          href="https://xflowgames.com/"
          target="_blank"
          className="da-badge-text da-text-color-dark-30" rel="noreferrer"
        >
          {`X-Flow ©${format(new Date(), 'yyyy')}`}
        </a>
      </Footer>
    </Layout>
  );
};

LayoutInner.propTypes = {
  children: PropTypes.any.isRequired,
};

export default LayoutInner;
