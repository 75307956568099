import React, { useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { useDispatch } from 'react-redux';
import Config from '../../../config/index';
import { refreshTokenSetup } from '../../utils/refreshToken';
import { mkNotification } from '../../../lib/mkNotification';
import { getAuthUser } from '../../../domains/login/feature/authSlice';



const LoginButton = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: Config.GOOGLE_CLIENT_ID,
        scope: '',
      });
    };

    gapi.load('client:auth2', initClient);
  });

  const onSuccess = (response) => {
    sessionStorage.setItem('auth', JSON.stringify(response));
    dispatch(getAuthUser({
      token: response.tokenObj.access_token,
      expired_in: response.tokenObj.expires_at,
    }));

    refreshTokenSetup(response);
  };

  const onFailure = () => {
    mkNotification('error', 'Ошибка авторизации! Свяжитесь с командой разработки.');
  };

  return (
    <GoogleLogin
      theme='dark'
      clientId={Config.GOOGLE_CLIENT_ID}
      buttonText="Войти с помощью Google"
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy="single_host_origin"
      style={{ marginTop: '100px' }}
      isSignedIn
    >
      <div style={{ fontSize: 15 }}>
        Войти с помощью Google
      </div>
    </GoogleLogin>
  );
};

export default LoginButton;
