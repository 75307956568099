import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import WidgetNewUsers from '../../../components/WidgetNewUsers';
import WidgetAvgAnswers from '../../../components/WidgetAvgAnswers';
import WidgetAvgTime from '../../../components/WidgetAvgTime';
import { getWidgetsData, resetWidgetsData, selectWidgetsData, selectWidgetsLoading } from './feature/dashboardSlice';



const PageDashboard = () => {
  const dispatch = useDispatch();
  const widgetsData = useSelector(selectWidgetsData);
  const widgetsLoading = useSelector(selectWidgetsLoading);

  useEffect(() => {
    dispatch(getWidgetsData());

    return () => {
      dispatch(resetWidgetsData());
    };
  }, []);

  return (
    <Row gutter={[ 16, 16 ]}>
      <Col xs={24} sm={12} lg={9}>
        <WidgetNewUsers
          title='Всего пользователей'
          data={widgetsData}
          isLoading={widgetsLoading}
        />
      </Col>

      <Col xs={24} sm={12} lg={7}>
        <WidgetAvgAnswers
          title='Верных ответов'
          isLoading={widgetsLoading}
          data={widgetsData}
        />
      </Col>

      <Col xs={24} sm={12} lg={8}>
        <WidgetAvgTime
          title='Среднее время'
          isLoading={widgetsLoading}
          data={widgetsData}
        />
      </Col>
    </Row>

  );
};


export default PageDashboard;
