import { Modal } from 'antd';



export const openWarningModal = () => {
  Modal.warning({
    title: 'Внимание!',
    content: 'Вы забыли указать верный ответ на вопрос.',
    okText: 'Окей',
  });
};
