import React from 'react';
import { Card, Col, Progress, Row, Spin } from 'antd';
import PropTypes from 'prop-types';



const WidgetAvgAnswers = ({ title, data, isLoading }) => {
  return (
    <Card>
      <Row justify="space-between">
        <h1>{title}</h1>
      </Row>

      <Spin spinning={isLoading} tip="Загрузка...">
        <Row justify="space-between">
          <Col>
            <h1 className='text-secondary'>
              {`${data?.answers?.avg_right_questions ?? '---'} / ${data?.answers?.total_questions ?? '---'}`}
            </h1>
          </Col>
          <Col className='flex-row-center'>
            <Progress
              style={{
                marginTop: '-20px',
                paddingBottom: '20px',
              }}
              type="circle"
              strokeColor={{
                '0%': 'rgba(86,169,162,0.36)',
                '100%': '#56A9A2',
              }}
              percent={data?.answers?.percent ?? '---'}
            />
          </Col>
        </Row>
      </Spin>
    </Card>
  );
};


WidgetAvgAnswers.propTypes = {
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  data: PropTypes.object,
};

WidgetAvgAnswers.defaultProps = {
  isLoading: false,
  data: {},
};

export default WidgetAvgAnswers;
